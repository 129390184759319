export function battle() {
  const wsuri = process.env.NODE_ENV === 'production'
    ? process.env.VUE_APP_WS_URL
    : 'wss://api.clashcase.com/ws/';
  // Ensure URL starts with wss:// or ws://
  if (!wsuri.startsWith('wss://') && !wsuri.startsWith('ws://')) {
    return `wss://${wsuri}`;
  }
  return wsuri;
}

export function openBox() {
  const wsuri = process.env.NODE_ENV === 'production'
    ? process.env.VUE_APP_WSS_URL
    : 'wss://api.clashcase.com/wss/';
  // Ensure URL starts with wss:// or ws://
  if (!wsuri.startsWith('wss://') && !wsuri.startsWith('ws://')) {
    return `wss://${wsuri}`;
  }
  return wsuri;
}

// export function battle() {
//   // const wsuri = "wss://fzxbwl.com/ws/";
//   // const wsuri = "wss://cz-chunxinhuanbao.com/ws/";
//   // const wsuri = "ws://192.168.101.12:7654/";
//   // const wsuri = "ws://192.168.149.1:7654/";
// // const wsuri = "wss://t.ym4954.armin.cc/ws/";
//   const wsuri = "wss://www.luckykx.com/ws/";
//   return wsuri;
// }
// export function openBox() {
//   // const wsuri = "wss://fzxbwl.com/wss/";
//   // const wsuri = "wss://cz-chunxinhuanbao.com/wss/";
//   // const wsuri = "ws://192.168.101.12:7655/";
//   // const wsuri = "ws://192.168.149.1:7655/";
// // const wsuri = "wss://t.ym4954.armin.cc/wss/";
//   const wsuri = "wss://www.luckykx.com/wss/";
//   return wsuri;
// }
