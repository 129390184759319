var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slide"},[_c('div',{staticClass:"lately"},[_c('ul',{staticClass:"slide-ul",attrs:{"id":"slide-ul"}},[_c('div',{staticClass:"div-li",attrs:{"id":"div-li"}},_vm._l((_vm.boxList),function(item1,index1){return _c('li',{key:index1,ref:'item'+index1,refInFor:true,style:({
            backgroundImage:'linear-gradient(' + item1.color1 + ',' + item1.color2 + ',' + item1.color3 + ',' + item1.color4 + ')',
            borderColor:item1.color4
          }),attrs:{"id":'li'+index1},on:{"click":function($event){return _vm.getBox(item1.box_id)}}},[_c('div',{staticClass:"slide-warp"},[_c('div',{staticClass:"left"},[_c('img',{attrs:{"src":item1.imageUrl}})]),_c('div',{staticClass:"right"},[_c('h4',{staticClass:"r-2",style:({color:item1.color})},[_vm._v(_vm._s(item1.skin_name))]),_c('h5',[_vm._v(" 打开 "),_c('span',[_vm._v(_vm._s(item1.box_name))]),_vm._v(" 获得 ")]),_c('h6',[_c('img',{attrs:{"src":item1.player_img}}),_c('span',{staticStyle:{"color":"#ADC8CB"}},[_vm._v(_vm._s(item1.player_name))])])])]),_c('span',{style:({
              backgroundColor: item1.color,
            })}),_c('span',{staticClass:"back"}),_c('div',{staticClass:"ul-line",style:({
                  backgroundColor:item1.color4
              })}),_c('div',{staticClass:"ul-line-1"})])}),0)])]),_c('div',{staticClass:"clear"})])
}
var staticRenderFns = []

export { render, staticRenderFns }